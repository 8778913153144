import React, {Component} from 'react';
import {APP_STORE_LINK, GOOGLE_PLAY_LINK} from "../../constants";
import appStoreLogo from "../../assets/images/appStore.png";
import googlePlayLogo from "../../assets/images/googlePlay.png";

import './styles.scss';

class StoreLinks extends Component {
    render() {
        return (
            <div className='store_links_container'>
                <div className='store_links_wrapper'>
                    <div className='store_link'>
                        <a href={GOOGLE_PLAY_LINK}><img src={googlePlayLogo} alt=""/></a>
                    </div>

                    <div className='store_link'>
                        <a href={APP_STORE_LINK}><img src={appStoreLogo} alt=""/></a>
                    </div>

                </div>
            </div>
        );
    }
}

export default StoreLinks;