import React from 'react';

const NoVideoText = () => {
    return (
        <div className='empty_video_text'>
            <p>
                Video is not ready yet,
                try 5 sec later
            </p>
        </div>
    );
}

export default NoVideoText