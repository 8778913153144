import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import Main from "./pages/Main";
import Logo from "./components/Logo/Logo";

function App() {
    return (
        <>
            <header>
                <Logo/>
            </header>

            <main>
                <BrowserRouter>
                    <Switch>
                        <Route path='/:hash' component={Main} exact/>
                    </Switch>
                </BrowserRouter>
            </main>
        </>
    );
}

export default App;
