import React, {Component} from 'react';
import logo from "../../assets/images/logo.svg";

import './styles.scss';

class Logo extends Component {
    render() {
        return (
            <div className='logo_container'>
                <div className='logo_wrapper'>
                    <img className='logo' src={logo} alt=""/>
                </div>
            </div>
        );
    }
}

export default Logo;