import React, {Component} from 'react';
import axios from 'axios';

import './styles.scss';

import VideoIframe from "./VideoIframe";
import NoVideoText from "./NoVideoText";
import {API_URL} from "../../constants";

class Video extends Component {

    state = {
        videoSrc: null //'https://www.youtube.com/embed/tNkZsRW7h2c'
    }

    componentDidMount() {

        axios.post(`${API_URL}?hash=${this.props.hash}`)
            .then(response => {
                this.setState({
                    videoSrc: response.data.src
                });
            })
            .catch(error => {
                if (error.response.status && error.response.status === 404) {
                    this.setState({
                        videoSrc: null
                    })
                }
            });
    }

    render() {
        return (
            <div className='video_container'>
                <div className='video_wrapper'>
                    <div className="video_wrapper__video">

                        {
                            this.state.videoSrc
                                ?
                                <VideoIframe videoSrc={this.state.videoSrc}/>
                                :
                                <NoVideoText/>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default Video;