import React, {Component} from 'react';
import Video from "../components/Video/Video";
import StoreLinks from "../components/StoreLinks/StoreLinks";
import {APP_NAME} from "../constants";

class Main extends Component {

    state = {}

    componentWillMount(){
        document.title = APP_NAME;

        const { match: { params } } = this.props

        this.setState({
            hash: params.hash
        })
    }

    render() {
        return (
           <>
               <Video hash={this.state.hash} />

               <StoreLinks/>
           </>
        )
    }
}

export default Main;